import React, { useEffect, useState } from 'react';
import { P, Ul, Li } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import Link from '@dnb/link';
import { SimpleHeader } from '@dnb/header';

function PageIndex() {
  const [sweets, setSweets] = useState([]);

  useEffect(() => {
    setSweets(['Ice cream', 'Chocolate', 'Sour candy', 'Cake']);
  }, []);

  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>

      <SimpleHeader title="Welcome to framework/dynamic-routing-demo!" />

      <P>
        This list of generated links can go to the same dynamic routing page
      </P>
      <Ul>
        {sweets.map((sweet) => (
          <Li>
            <Link
              to={`/dynamic-routing-demo/${encodeURIComponent(sweet)}`}
            >{`Go to ${sweet}`}</Link>
          </Li>
        ))}
      </Ul>
    </>
  );
}

export default PageIndex;
